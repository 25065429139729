import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Map from './Map.js';
import moment from 'moment';

// url and folder
const url00 = "https://sfr.umd.edu";
const ffn_php = "/api/sfr/getImgs1.php";
const ffn_phpm = "/api/sfr/getImgsM.php";
const fdn00 = "/home/jundong/www";

const MapComponent = ({ dates, satellites, region }) => {
    const [images, setImages] = useState(null);

    // console.log("MapComponent: " + dates);

    useEffect(() => {
        if (dates) {
            /* Get parameters for getImgs */
            var dateArray;
            var satelliteFolders;
            var times;
            if (region === "msfr") {
                dateArray = getDates(dates[0], dates[1]);
                satelliteFolders = [""];
                times = [moment(dates[0]).format("HHmmss"), moment(dates[1]).format("HHmmss")];
            }

            if (region === "conus" || region === "alaska" || region === "europe" || region ==="SAfrica") {
                const extensions = ["npp", "n20", "n19", "mob", "moc", "n21", "gpm", "f16", "f17", "f18"];
                dateArray = getDates(dates[0], dates[1]);
                satelliteFolders = [""];
                for (let i = 0; i < satellites.length; i++) {
                    if (satellites[i]) {
                        satelliteFolders.push(extensions[i]);
                    }
                }
                // times = ["000000", "240000"];
                times = [moment(dates[0]).format("HHmmss"), moment(dates[1]).format("HHmmss")];
            }
            console.log('dateArray');  console.log(dateArray);
            console.log('satellitesFolders');  console.log(satelliteFolders);
            console.log('times');  console.log(times);
            console.log('region');  console.log(region);

            let url_php = url00 + ffn_php
            if (region === "msfr")
                url_php = url00 + ffn_phpm;
            //console.log(url_php)

            $.ajax({
                type: "GET",
                crossDomain: true,
                url: url_php,
                data: { days: dateArray, satellites: satelliteFolders, times: times, region: region },
                dataType: 'json',
                error: function (jqxhr, textstatus, errorthrown) {
                    // console.log('jd, error');
                    // console.log(textstatus);
                    // console.log(errorthrown);
                },
                success: function (obj, textstatus) {
                    console.log('jd, success')
                    console.log(obj);

                    if (!('error' in obj)) {
                        // console.log(obj.length)
                        if (obj.length > 0) {

                            var imgRegex;
                            if (region === "msfr") {
                                imgRegex = /[0-9]{8}_[0-9]{6}/;
                            } else {
                                imgRegex = /S[0-9]{8}_[0-9]{6}/;
                            }
                            // console.log('reg');  console.log(region);

                            /* Sort each day's urls by the time of the swath */
                            for (let day = 0; day < obj.length; day++) {
                                // console.log('day: ' + day);
                                // console.log(obj[day]);
                                obj[day] = obj[day].sort(function (a, b) {
                                    return a[0].match(imgRegex)[0].localeCompare(b[0].match(imgRegex))
                                });

                                for (let img = 0; img < obj[day].length; img++) {
                                    obj[day][img][0] = obj[day][img][0].replace(fdn00, url00);
                                    // console.log(obj[day][img]);
                                }
                            }
                            obj = obj.flat();
                            // console.log(obj);
                            setImages(obj);
                        }
                        else {
                            setImages(null);
                            console.log("No results returned by PHP call.");
                        }
                    }
                    else {
                        console.log(obj.error);
                    }
                }

            });
        }
    }, [dates, satellites, region])

    return (
        <div>
            <Map images={images} region={region} />
        </div>
    );
}

/* Takes a starting date and stopping date and returns all the dates between them as an array inclusive */
function getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDateMoment = moment(stopDate);
    while (currentDate <= stopDateMoment) {
        var formatDate = moment(currentDate).format('YYYYMMDD');
        dateArray.push([formatDate.substring(0, 4), formatDate.substring(4, 6), formatDate.substring(6, 8)]);
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}

export default MapComponent;