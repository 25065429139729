import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap'
import '../App.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";

import { min } from 'moment';


const NavRight = ({ getParams, region }) => {
    const date = new Date();

    // change to start/end date|time
    // const UTCdate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    // const [selectedDates, setSelectedDates] = useState([UTCdate, UTCdate]);
    const UTCStartDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    const UTCEndDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), Math.floor(date.getUTCMinutes() / 60+1) * 60);
    const [selectedDates, setSelectedDates] = useState([UTCStartDate, UTCEndDate]);

    const [satellites, setSatellites] = useState(
        new Array(10).fill(true)
    );

    /* Code for choosing satellites */
    const handleOnChange = (position) => {
        var satellitesCopy = [...satellites];
        satellitesCopy[position] = !satellites[position]
        setSatellites(satellitesCopy);
    }

    useEffect(() => {
      var endBound = new Date(selectedDates[0]);
      endBound.setDate(selectedDates[0].getDate() + 1);          
      if (selectedDates[1] < selectedDates[0]) {
        // setSelectedDates([selectedDates[0], selectedDates[0]])
        setSelectedDates([selectedDates[0], endBound])
      }
      else {
          getParams({ dates: selectedDates, satellites: null })
      }
    }, [selectedDates, getParams])

    const getStartMaxTime = () => {
      if (UTCStartDate.getTime() === new Date(selectedDates[0].getFullYear(), selectedDates[0].getMonth(), selectedDates[0].getDate()).getTime()) {
          return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes())
      } else {
          return (new Date()).setHours(23, min = 59)
      }
    }

    const getEndMaxTime = () => {
      if (selectedDates[1] && UTCStartDate.getTime() === new Date(selectedDates[1].getFullYear(), selectedDates[1].getMonth(), selectedDates[1].getDate()).getTime()) {
          return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes())
      } else {
          return (new Date()).setHours(23, min = 59)
      }
    }

    const getEndMinTime = () => {
      if (selectedDates[1] && new Date(selectedDates[0].getFullYear(), selectedDates[0].getMonth(), selectedDates[0].getDate()).getTime() === new Date(selectedDates[1].getFullYear(), selectedDates[1].getMonth(), selectedDates[1].getDate()).getTime()) {
          return selectedDates[0]
      } else {
          return (new Date()).setHours(0, min = 0)
      }
    }

    const getEndMaxDate = () => {
      var endBound = new Date(selectedDates[0]);
      endBound.setDate(selectedDates[0].getDate() + 7);
      if (endBound > UTCStartDate) {
          return UTCStartDate;
      }
      else {
          return endBound;
      }
    }

    const handleStartDate = (date) => {
      if (new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() === new Date(selectedDates[0].getFullYear(), selectedDates[0].getMonth(), selectedDates[0].getDate()).getTime()) {
          setSelectedDates([date, selectedDates[1]])
      } else {
          setSelectedDates([date, null])
      }
    }


    /* Code for choosing all satellites */
    const handleOnAll = () => {
        var pos;
        var satellitesCopy = [...satellites];
        const sats = ["npp", "n20", "n19", "mob", "moc", "n21", "gpm", "f16", "f17", "f18"];

        var all = document.getElementById("all");
        // console.log("all.checked");  console.log(all.checked);
        if (all.checked === true)  {
            for (pos=0; pos<10; pos++)  {
                satellitesCopy[pos] = true;
                document.getElementById(sats[pos]).checked = true;
            }
        }
        else  {
            for (pos=0; pos<10; pos++)  {
                satellitesCopy[pos] = false;
                document.getElementById(sats[pos]).checked = false;
            }
        }
        // console.log('satellitesCopy');  console.log(satellitesCopy);
        setSatellites(satellitesCopy);
    }

    useEffect(() => {
        getParams({ dates: selectedDates, satellites: satellites })
    }, [selectedDates, satellites, getParams])

    const popover = (
        <Popover id="popover-basic">
            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
            <Popover.Body>
                <ul>
                <li>Select the region to display by using Google Maps zoom and pan tools or by using the Box
                    Selection function in the Control Panel (lower right corner). Check the Box Selection box to
                    activate and uncheck to deactivate this function.</li>
                <li>Sample product value by clicking on the image</li>
                <li>Toggle the Loop button to start or stop the looping process</li>
                <li>Use the arrows to scroll through the images manually</li>
                <li>Use the slider in the Control Panel to adjust opacity</li>
                <li>Check the Show Counties box to show county boundaries when zoomed in</li>
                </ul>
          </Popover.Body>
        </Popover>
      );
      
    const Instructions = () => (
        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
            <Button variant="primary" style={{fontSize: "20px"}}>Instructions</Button>
        </OverlayTrigger>
    );

    const [startDate, setStartDate] = useState(new Date());
    const years = range(2021, getYear(new Date()) + 1, 1);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return (
        <div>
            {region === "conus"
                ? <p>Images generated using direct broadcast data received at <a href="https://cimss.ssec.wisc.edu/" rel="noreferrer" target="_blank">CIMSS</a>.</p>
                : <p>Images generated using direct broadcast data received at <a href="https://gina.alaska.edu/" rel="noreferrer" target="_blank">GINA</a>.</p>}

            <p><Instructions /></p>

            <h5>
                <a href="https://drive.google.com/file/d/1v_oqSilIN9nXtk3bzc-MmLHayN-4xHiX/view?usp=sharing"
                    target="_blank" rel="noreferrer noopener">SFR Quick Guide</a>
            </h5>

            <hr style={{ color: "black" }} />
            
            <h7>The date range is limited to 7 days.</h7>
            <h5>Start Date</h5>
            <div className="date-picker" style={{ fontSize: "15px" }}>
                <Row>
                    <DatePicker

                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                          </button>
                          <select
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                          </button>
                        </div>
                      )}

                        selected={selectedDates[0]}
                        // onChange={(date) => setSelectedDates([date, date])}
                        onChange={(date) => handleStartDate(date)}
                        // showYearDropdown
                        selectsStart
                        startDate={selectedDates[0]}
                        yearDropdownItemNumber={4}
                        maxDate={UTCStartDate}
                        showTimeSelect
                        timeIntervals={60}
                        minTime={(new Date()).setHours(0, min = 0)}
                        maxTime={getStartMaxTime()}
                        dateFormat="MM/dd/yyyy HH:mm"
                        timeFormat="HH:mm"
                    />
                </Row>
            </div>

            <h5>End Date</h5>
            <div className="date-picker" style={{ fontSize: "15px" }}>
                <Row>
                    <DatePicker
                    
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                          </button>
                          <select
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                          </button>
                        </div>
                      )}

                        selected={selectedDates[1]}
                        onChange={(date) => setSelectedDates([selectedDates[0], date])}
                        // showYearDropdown
                        selectsEnd
                        startDate={selectedDates[0]}
                        endDate={selectedDates[1]}
                        minDate={selectedDates[0]}
                        yearDropdownItemNumber={4}
                        // maxDate={UTCdate}
                        maxDate={getEndMaxDate()}
                        showTimeSelect
                        timeIntervals={60}
                        minTime={getEndMinTime()}
                        maxTime={getEndMaxTime()}
                        dateFormat="MM/dd/yyyy HH:mm"
                        timeFormat="HH:mm"
                    />
                </Row>
            </div>

            <hr style={{ color: "black" }} />

            <Row className='ml-3'>
                <Col><h5>Satellites:</h5></Col>
                <Col>
                <Form.Check
                    inline
                    id = {'all'}
                    label="All"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnAll()}
                />
                </Col>
                <Col></Col>
            </Row>
            <Form style={{ margin: "0px" }}>
                <Form.Check
                    inline
                    id = {'n21'}
                    label="NOAA-21"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(5)}
                />
                <Form.Check
                    inline
                    id = {'n20'}
                    label="NOAA-20"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(1)}
                />
                <Form.Check
                    inline
                    id = {'npp'}
                    label="S-NPP"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(0)}
                />
            </Form>
            <Form style={{ margin: "0px" }}>
                <Form.Check
                    inline
                    id = {'n19'}
                    label="NOAA-19"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(2)}
                />
                <Form.Check
                    inline
                    id = {'mob'}
                    label="Metop-B"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(3)}
                />
                <Form.Check
                    inline
                    id = {'moc'}
                    label="Metop-C"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(4)}
                />
            </Form>
            <Form>    
                <Form.Check
                    inline
                    id = {"f16"}
                    label="F16"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(7)}
                />
                <Form.Check
                    inline
                    id = {"f17"}
                    label="F17"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(8)}
                />
                <Form.Check
                    inline
                    id = {"f18"}
                    label="F18"
                    type={'checkbox'}
                    defaultChecked="checked"
                    style={{ width: "90px", fontSize: "14px" }}
                    onChange={() => handleOnChange(9)}
                />
            </Form>
            <Form style={{ margin: "0px" }}>
                <Form.Check
                    inline
                    id = {'gpm'}
                    label={"GPM"}
                    type={'checkbox'}
                    defaultChecked="checked"
                    onChange={() => handleOnChange(6)}
                    style={{ width: "90px", fontSize: "14px" }}
                />
            </Form>
            
            <hr style={{ color: "black" }} />

            <h5 style={{color: "#F00000"}}>New colorbar with max of 0.4 in/hr.</h5>

        </div >
    );
}

export default NavRight;



// function Instructions() {
//     const renderTooltip = (props) => (
//       <Tooltip id="button-tooltip" {...props}>
//             <ul>
//                 <li>Select the region to display by using Google Maps zoom and pan tools or by using the Box
//                     Selection function in the Control Panel (lower right corner). Check the Box Selection box to
//                     activate and uncheck to deactivate this function.</li>
//                 <li>Sample product value by clicking on the image</li>
//                 <li>Toggle the Loop button to start or stop the looping process</li>
//                 <li>Use the arrows to scroll through the images manually</li>
//                 <li>Use the slider in the Control Panel to adjust opacity</li>
//                 <li>Check the Show Counties box to show county boundaries when zoomed in</li>
//             </ul>
//       </Tooltip>
//     );
  
//     return (
//       <OverlayTrigger
//         placement="bottom"
//         delay={{ show: 100, hide: 200 }}
//         overlay={renderTooltip}
//       >
//         <Button variant="success">Display Instructions</Button>
//       </OverlayTrigger>
//     );
//   }



